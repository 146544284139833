
import { defineComponent, reactive, onMounted, computed } from 'vue'
import checkUserSettings from '@/mixins/checkUserSettings'
import formatCurrency from '@/filters/CurrencyFormatWithCurrencyHidden'
import BackButton from '@/components/UI/BackButton.vue'
import Popup from '@/components/Popup.vue'
import ToggleableContent from '@/components/toggleableContent.vue'
import Checkbox from '@/components/UI/Checkbox.vue'
import Button from '@/components/UI/Button.vue'
import { ActionTypes } from '@/store/options/actions'
import { carPolicyActionTypes } from '@/store/carPolicy/actions'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { VueCookieNext } from 'vue-cookie-next'
import Loader from '@/components/UI/Loader.vue'
import ConvertToCommas from '@/filters/ConvertToCommas'
import getTranslatedFuelType from '@/filters/getTranslatedFuelType'
import getTranslatedBodyType from '@/filters/getTranslatedBodyType'
import { mandatoryAccessoriesType } from '@/types'
export default defineComponent({
  components: {
    BackButton,
    ToggleableContent,
    Checkbox,
    Button,
    Loader,
    Popup
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const carOrderDetailsById = computed(() => store.state.options.carOrderDetailsById)
    const vehicleDetails = computed(() => store.state.options.carOrderDetailsById.vehicleDetails)
    const carOrderLoader = computed(() => store.state.options.carOrderLoader)
    const currentUserDetails = computed(() => store.state.auth.currentUser)
    const { displayUserSettingPopup, goToAccount } = checkUserSettings()
    const filteredColorsOptions = reactive({ value: [] })
    const carOrderAdd = reactive({
      vehicleId: route.params.vehicleId,
      monthlyCost: 13.74,
      carPrice: 12.25,
      chargingFacilities: [] as mandatoryAccessoriesType[]
    })
    const checkboxesErrors = reactive({})
    const checkboxes = reactive([
      { id: 0, isActive: false },
      { id: 1, isActive: false },
      { id: 2, isActive: false }
    ])
    onMounted(async () => {
      if (!displayUserSettingPopup.value) {
        await store.dispatch(ActionTypes.GET_CARORDERBYID, {
          id: JSON.parse(VueCookieNext.getCookie('latestcarOrderId'))
        })
        const noDuplicatesObject = {}
        filteredColorsOptions.value = carOrderDetailsById.value['options']
          .map((item) => {
            return [item, ...item['children']]
          })
          .flat()
          .filter((obj) => !noDuplicatesObject[obj.optionId] && (noDuplicatesObject[obj.optionId] = true))
      }    
    })
    const scrollToSection = (sectionId) => {
      const element = document.querySelector(`#${sectionId}`) as HTMLElement
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
    const validateCheckboxes = () => {
      delete checkboxesErrors['specificationError']
      delete checkboxesErrors['financialImpactError']
      delete checkboxesErrors['documentError']
      let sectionId = ''
      if (!checkboxes[0]['isActive']) {
        checkboxesErrors['specificationError'] = 'required'
        sectionId = 'specificationSection'
      }
      if (!checkboxes[1]['isActive']) {
        checkboxesErrors['financialImpactError'] = 'required'
        if (!sectionId) {
          sectionId = 'financialSection'
        }
      }
      if (!checkboxes[2]['isActive']) {
        checkboxesErrors['documentError'] = 'required'
        if (!sectionId) {
          sectionId = 'documentSection'
        }
      }
      if (Object.getOwnPropertyNames(checkboxesErrors).length === 0) {
        return true
      } else {
        scrollToSection(sectionId)
      }
    }
    const approveCarOrder = async (status) => {
      if (validateCheckboxes()) {
        carOrderAdd['co2Emission'] = vehicleDetails.value.combined_62203
        carOrderAdd['options'] = carOrderDetailsById.value.options
        carOrderAdd['otherAccessories'] = carOrderDetailsById.value.otherAccessories
        carOrderAdd['mandatoryAccessories'] = carOrderDetailsById.value.mandatoryAccessories
        carOrderAdd['chargingFacilities'] = carOrderDetailsById.value.chargingFacilities
        carOrderAdd['status'] = status
        await store.dispatch(ActionTypes.ADD_CARORDER, { carOrderData: carOrderAdd })
      }
    }
    const downloadCarPolicyDocument = () => {
      store.dispatch(carPolicyActionTypes.DOWNLOAD_CARPOLICYDOCUMENT, {
        companyId: VueCookieNext.getCookie('companyId'),
        carPolicyId: VueCookieNext.getCookie('employeeCarPolicyId')
      })
    }
    const groupByColors = (choice) => {
      return filteredColorsOptions.value.filter((item) => item['category'] == choice)
    }
    const groupByOptions = () => {
      return filteredColorsOptions.value.filter(
        (item) => item.category != 'Exterior colour' && item.category != 'Interior colour'
      )
    }
    return {
      checkboxes,
      formatCurrency,
      carOrderDetailsById,
      carOrderLoader,
      ConvertToCommas,
      vehicleDetails,
      getTranslatedFuelType,
      getTranslatedBodyType,
      currentUserDetails,
      approveCarOrder,
      checkboxesErrors,
      downloadCarPolicyDocument,
      groupByColors,
      groupByOptions,
      displayUserSettingPopup,
      goToAccount
    }
  }
})
